import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import TopCardWrap from "@/content/use-cases/TopCardWrap";
import GraphicText from "@/layout/GraphicText";

import Header from "@components/head";
import BottomLayout from "@/layout/BottomLayout";

import {
  Text,
  List,
  Button,
  ListItem,
  Box,
  Link as ChakraLink,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { HOST_URL } from "@/utils/constant";

const { useState } = React;

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/use-cases/user-research`}
      title="Build Better Products with True User Insights | Airgram for User Research Team"
      description="Collect, analyze and demonstrate actionable data to stakeholders more effectively. Airgram makes user research work easier through real-time transcription, timestamped notes and HD video recordings."
    />
  );
};

const IndexPage = () => {
  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_use_case"
          description="Better user research leads to better product."
          buttonText="Get Started Free"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <TopCardWrap>
        <Text w="80%" textAlign="center" fontSize="32px" as="h1">
          Build better products with true user insights
        </Text>
        <Text textAlign="center" fontSize={18}>
          Collect, analyze and demonstrate actionable data to stakeholders more
          effectively.
        </Text>

        <ChakraLink
          _hover={{
            textDecor: "none",
          }}
          lineHeight="52px"
          target="_blank"
          mt="24px"
          href="https://app.airgram.io/signup"
        >
          <Button
            data-gtm="web_sign_up_cta_btn_use_case"
            size="lg"
            colorScheme="brand"
          >
            Get Started Free
          </Button>
        </ChakraLink>
      </TopCardWrap>

      <VStack my="80px" gap={{ base: "40px", md: "80px", lg: "120px" }}>
        {/* 左边图片，右边文字的布局 */}
        <GraphicText
          placement="right"
          title="Keep the user interview running more smoothly"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1708x1008/b420c52faa/usecaseuserresearch1.png"
              alt="Use Case Team"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Conduct efficient online user interviews. AI note-taker records
              and transcribes the audio in real time, allowing you to listen to
              your users attentively.
            </Text>
            <Text fontSize={18}>
              Never forget to record any vital interviews via auto joining Zoom
              calls. Multilingual AI assistant automatically joins all or
              particular scheduled meetings on time.
            </Text>
          </Flex>
        </GraphicText>

        {/* 左边文字，右边图片的布局 */}
        <GraphicText
          title="Boost the efficiency of data analysis with accuracy"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1758x1108/b8bc22da3b/usecaseuserresearch2.png"
              alt="Google Meet Transcript"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Quickly get an accurate record of the interview for the analysis
              process. Play back the video recording at different speeds. Listen
              to participants’ remarks separately.
            </Text>

            <Text fontSize={18}>
              Take the highlights out of a lengthy transcript in seconds by
              utilizing:
            </Text>

            <List pl={0}>
              <ListItem>🔹 Timestamped notes</ListItem>
              <ListItem>
                🔹 Export transcript with speaker identification
              </ListItem>
              <ListItem>🔹 AI-powered entity extraction</ListItem>
              <ListItem>🔹 Find and replace</ListItem>
            </List>
          </Flex>
        </GraphicText>

        <GraphicText
          placement="right"
          title="Easily communicate your insights to stakeholders"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1535x1022/6cce2de83b/usecaseuserresearch3.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Present the core insights and actionable next steps to the
              stakeholders. Various types of meeting information - transcript,
              notes, video recordings, and AI entity extraction - enable you to
              run a well-organized online presentation.
            </Text>
            <Text fontSize={18}>
              Share all the critical points with product managers, designers,
              developers, marketers via Slack, Notion, or export them to
              Microsoft Word, Google Docs. Foster transparency across the
              organization.
            </Text>
          </Flex>
        </GraphicText>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
